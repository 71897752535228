import React, { lazy, Suspense } from 'react';
import './App.css';
import ResponsiveAppBar from './Header/Header';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Footer from './Footer/Footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import MainLayout from './component/MainLayout';
import { CookiePopup } from './cookies';

// Lazy loaded components
const HomePage = lazy(() => import('./HomePage/HomePage'));
const Abha = lazy(() => import('./AbhaPage/Abha'));
const ContactPage = lazy(() => import('./contactusPage/ContactPage'));
const Solution = lazy(() => import('./SolutionPage/Solution'));
const DownloadAppPage = lazy(() => import('./downloadPage/DownloadAppPage'));
const Disclaimer = lazy(() => import('./Disclaimer/Disclaimer'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicyPage/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./termsOfUsePage/TermsOfUse'));
const ShippingPolicy = lazy(() => import('./shippingPolicyPage/ShippingPolicy'));
const Cancelletion = lazy(() => import('./cancelletionPolicyPage/Cancelletion'));
const NotFoundPage = lazy(() => import('./NotFoundPage/NotFoundPage'));

const theme = createTheme({
  // ... your other theme overrides
});

const CaseSensitiveGuard = ({ children, exactPath }) => {
  const location = useLocation();
  if (location.pathname !== exactPath) {
    return <NotFoundPage />;
  }
  return children;
};

function App() {
  return (
    <>
      <Helmet>
        <meta name="google-site-verification" content="google96dc4a127ddefcb2" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <MainLayout />
            <CookiePopup />
            <main>
              <Suspense fallback={<div>Loading...</div>}> {/* Fallback component while loading */}
                <Routes>
                  <Route path="/" element={
                    <CaseSensitiveGuard exactPath="/">
                      <HomePage />
                    </CaseSensitiveGuard>
                  } />
                  <Route path="/abha" element={
                    <CaseSensitiveGuard exactPath="/abha">
                      <Abha />
                    </CaseSensitiveGuard>
                  } />
                  <Route path="/contactus" element={
                    <CaseSensitiveGuard exactPath="/contactus">
                      <ContactPage />
                    </CaseSensitiveGuard>
                  } />
                  <Route path="/solutions" element={
                    <CaseSensitiveGuard exactPath="/solutions">
                      <Solution />
                    </CaseSensitiveGuard>
                  } />
                  <Route path="/download" element={
                    <CaseSensitiveGuard exactPath="/download">
                      <DownloadAppPage />
                    </CaseSensitiveGuard>
                  } />
                  <Route path="/disclaimer" element={
                    <CaseSensitiveGuard exactPath="/disclaimer">
                      <Disclaimer />
                    </CaseSensitiveGuard>
                  } />
                  <Route path="/privacy-policy" element={
                    <CaseSensitiveGuard exactPath="/privacy-policy">
                      <PrivacyPolicy />
                    </CaseSensitiveGuard>
                  } />
                  <Route path="/terms-of-use" element={
                    <CaseSensitiveGuard exactPath="/terms-of-use">
                      <TermsOfUse />
                    </CaseSensitiveGuard>
                  } />
                  <Route path="/shipping-policy" element={
                    <CaseSensitiveGuard exactPath="/shipping-policy">
                      <ShippingPolicy />
                    </CaseSensitiveGuard>
                  } />
                  <Route path="/cancellation-policy" element={
                    <CaseSensitiveGuard exactPath="/cancellation-policy">
                      <Cancelletion />
                    </CaseSensitiveGuard>
                  } />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Suspense>
            </main>
            <Footer />
          </div>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
